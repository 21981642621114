import React from 'react'
import Header from './Components/Header/Header'
import Her from './Components/Her'
import Experience from './Components/Experience'
import Search from './Components/Search'
import Download from './Components/Download'
import Footer from './Components/Footer'

const App = () => {
  return (
    <div className='App text-white overflow-hidden '>
      <Header/>
      <Her/>
      <Experience/>
      <Search/>
      <Download/>
      <Footer/>
    </div>
  )
}

export default App